<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-14 11:43:09
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="underdrive">
    <page-top :title="$t('supplier_submenu.99DB4A@Setting')">
      <span class="fa fa-cog fa-2x"></span>
    </page-top>
    <el-card>
      <section slot="header">
        <div slot="header" class="header">
          <i class="fa fa-info-circle"></i>
          <span class="title">
            {{
            $t('supplier_TransactionSettings.2435B2@Transaction_settings')
            }}
          </span>
        </div>
      </section>
      <el-form ref="form" :rules="rules" :model="form" label-width="200px">
        <el-row>
          <el-col :span="16" :offset="3">
            <el-form-item :label="$t('aboutus.24F080@Bank_Name') + '1'" prop="bank_name1">
              <el-input v-model="form.bank_name1"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.1A4DE1@Account_Name') + '1'" prop="account_name1">
              <el-input v-model="form.account_name1"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.C232EA@Account_Number') + '1'" prop="account_number1">
              <el-input v-model="form.account_number1"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.24F080@Bank_Name') + '2'" prop="bank_name2">
              <el-input v-model="form.bank_name2"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.1A4DE1@Account_Name') + '2'" prop="account_name2">
              <el-input v-model="form.account_name2"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.C232EA@Account_Number') + '2'" prop="account_number2">
              <el-input v-model="form.account_number2"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('restaurant_supplier_supplierList.5CA70A@min_order')"
              prop="amount_min"
            >
              <el-input type="number" @input.native="$inputLenght" v-model="form.amount_min"></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t(
                  'restaurant_supplier_supplierList.8FC08B@reduction_conditions'
                )
              "
              prop="full_slicing"
            >
              <el-input :min="0" type="number" @input.native="$inputLenght" v-model="form.full_slicing"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('restaurant_supplier_supplierList.2E31F9@fee')"
              prop="deliver_fee"
            >
              <el-input type="number" @input.native="$inputLenght" v-model="form.deliver_fee"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('restaurant_supplier_supplierList.0666FD@pay_type')"
              prop="pay_method"
            >
              <el-select
                v-model="form.pay_method"
                @change="overdue"
                :placeholder="$t('placeholder.C209ED@select')"
              >
                <el-option :label="$t('public.2E733D@Cash_on_delivery')" :value="1"></el-option>
                <el-option :label="$t('public.5D8689@Delayable_payment')" :value="2"></el-option>
                <el-option :label="$t('public.CC56D2@Designated_monthly_date')" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="form.pay_method == 2 || form.pay_method == 3"
              :label="$t('supplier_partner.AADE98@Late_payment_day')"
              prop="overdue"
            >
              <el-input
                v-model="form.overdue"
                type="number"
                @input.native="$inputNumber"
                style="width:180px"
              ></el-input>
              {{$t('aboutus.D4D5D3@day')}}
            </el-form-item>
            <el-form-item
              v-if="form.pay_method == 3"
              :label="$t('restaurant_supplier_addsuppluer.4AF4CD@specify_date')"
              prop="settle_date"
            >
              <!-- 1-31 -->
              <el-select v-model="form.settle_date" :placeholder="$t('placeholder.C209ED@select')">
                <el-option
                  v-for="item in 31"
                  :key="item"
                  :label="item + $t('aboutus.CAF889@day')"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('aboutus.66D050@delivery_time')">
              <div style="display:flex">
                <el-time-picker
                  v-model="form.delivery_time_start"
                  value-format="HH:mm"
                  format="HH:mm"
                ></el-time-picker>
                <span style="padding:0 5px;">-</span>
                <el-time-picker
                  value-format="HH:mm"
                  format="HH:mm"
                  v-model="form.delivery_time_end"
                ></el-time-picker>
              </div>
            </el-form-item>
            <el-form-item :label="$t('aboutus.D11489@Order_Deadline')">
              <el-time-picker v-model="form.cut_off_time" value-format="HH:mm" format="HH:mm"></el-time-picker>
            </el-form-item>
            <el-form-item :label="$t('public.AAE565@is_heloneself')">
              <el-checkbox :true-label="1" :false-label="0" v-model="form.heloneself"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <section class="btn-section">
        <KtButton
          :label="$t('public.A738BA@update')"
          :perm="3405"
          class="form-btn btn-yellow"
          @click="updateTradeSetting('form')"
        ></KtButton>
      </section>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
export default {
  data () {
    return {
      form: {
        heloneself:0,
        amount_min: "",
        full_slicing: "",
        deliver_fee: "",
        pay_method: "",
        overdue: "",
        settle_date: "",
        delivery_time_start: "",
        delivery_time_end: "",
        cut_off_time: "",
        bank_name1: "",
        account_name1: "",
        account_number1: "",
        bank_name2: "",
        account_name2: "",
        account_number2: ""
      },
      rules: {
        amount_min: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        full_slicing: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        deliver_fee: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        pay_method: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        overdue: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        settle_date: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        delivery_time_start: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        delivery_time_end: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" },
        cut_off_time: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: "blur" }
      }
    };
  },

  components: {
    pageTop,
    KtButton
  },
  created () {
    this.getTradeSetting();
  },
  methods: {
    getTradeSetting () {
      this.$http.getTradeSetting().then(res => {
        this.form = res.data;
        this.form.overdue = res.data.overdue;
      });
    },
    // 选择结算方式
    overdue (value) {
      if (value == 1) {
        this.form.overdue = "";
        this.form.settle_date = "";
      } else if (value == 2) {
        this.form.overdue = 1;
        this.form.settle_date = "";
      } else if (value == 3) {
        this.form.overdue = 1;
        this.form.settle_date = 1;
      }
    },
    updateTradeSetting (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http.updateTradeSetting(this.form).then(res => {
            console.log(res);
            this.getTradeSetting();
          });
        } else {
          this.$message.warning(
            this.$t("shop-goods.5E8023@Please_complete_the_form")
          );
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.underdrive {
  .header {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 600;
    span {
      margin-left: 10px;
    }
  }
  .tips {
    font-size: 12px;
    color: #999;
  }
  .btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
